define("build-content/valid-urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "routes": ["/cms/willhaben-rsv", "/cms/willhaben-150", "/cms/willhabenAT", "/cms/billa45", "/cms/financeads-at", "/cms/autoscout150", "/cms/offerista/flyer", "/cms/gutscheineat", "/cms/uim150", "/cms/milesandmore/10000", "/cms/offerista-push", "/cms/madvertise150", "/cms/150/amazon", "/cms/milesandmore", "/cms/150/best-choice", "/cms/300/best-choice", "/cms/300/amazon", "/cms/lidl-AT", "/cms/autoscout24at-2022", "/cms/gsg-300", "/cms/50-1", "/cms/zalando", "/cms/sovendus-300", "/cms/lieferando", "/cms/stroeer", "/cms/clark50", "/cms/50", "/cms/krone150", "/cms/uim", "/cms/150mm", "/cms/150ikea", "/cms/uimikea", "/cms/clark", "/cms/rubarbat", "/cms/payback", "/cms/energyhero", "/cms/autoscout24at", "/", "/cms/partnerships", "/cms/n26", "/cms/n26_2", "/cms/xing", "/cms/studo", "/cms/drei_3", "/cms/drei_4", "/cms/drei", "/cms/drei_2", "/cms/bunq", "/cms/huawei", "/careers", "/cms/partners", "/cms/ueber-uns", "/lead-success", "/cms/so-funktionierts"]
  };
  _exports.default = _default;
});