define("@clark/ember-brand-config/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const config = {
    brand: 'clark',
    brandFull: 'Clark AT',
    locale: {
      languageTag: 'de-at'
    },
    canonicalHost: 'goclark.at',
    layoutId: 'layoutAT',
    mainMenuSlug: 'main-menu-de-at',
    homeSlug: '/',
    leadSuccessSlug: 'lead-success',
    sovendusSlug: 'sovendus',
    trustPilotConfig: {
      default: {
        height: '240px',
        templateId: '54ad5defc6454f065c28af8b'
      },
      seoHero: {
        height: '18px',
        templateId: '5419b732fbfb950b10de65e5'
      },
      leadGen: {
        width: '120px',
        height: '82px',
        templateId: '53aa8807dec7e10d38f59f32',
        theme: 'dark'
      },
      footer: {
        height: '18px',
        templateId: '5419b732fbfb950b10de65e5'
      },
      widget: {
        locale: 'de-De',
        businessunitId: '55c0d2ac0000ff000581c5b0',
        width: '100%',
        theme: 'light',
        stars: '4,5',
        url: 'https://de.trustpilot.com/review/goclark.com/at',
        dataSchemaType: 'Organization'
      }
    },
    socialMediaLinks: [{
      url: 'https://www.facebook.com/goclark.at/',
      source: 'fb-ico'
    }, {
      url: 'https://twitter.com/clarkapp_at/',
      source: 'twitter-ico'
    }, {
      url: 'https://www.linkedin.com/company/goclark/',
      source: 'linkedin-ico'
    }, {
      url: 'https://www.instagram.com/clarkapp/',
      source: 'instagram-ico'
    }],
    disabledFooterLinks: ['/erstinformation'],
    adjustLink: 'https://app.adjust.com/16jy76c_vzrme58?fallback=https://www.goclark.at%2Fde%2Fsignup%20%0A'
  };
  var _default = config;
  _exports.default = _default;
});